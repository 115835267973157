import Xlane1 from "./xlane (1).webp";
import Xlane2 from "./xlane (2).webp";
import Xlane3 from "./xlane (3).webp";
import Xlane4 from "./xlane (4).webp";
import Xlane5 from "./xlane (5).webp";
import Xlane6 from "./xlane (6).webp";
import Xlane7 from "./xlane (7).webp";
import Xlane8 from "./xlane (8).webp";
import Xlane9 from "./xlane (9).webp";
import Xlane10 from "./xlane (10).webp";
import Xlane11 from "./xlane (11).webp";
import Xlane12 from "./xlane (12).webp";

const xlane = [
  Xlane1,
  Xlane2,
  Xlane3,
  Xlane4,
  Xlane5,
  Xlane6,
  Xlane7,
  Xlane8,
  Xlane9,
  Xlane10,
  Xlane11,
  Xlane12,
];

export default xlane;
