import React from "react";
import "./home.scss";
import Navbar from "../../section/navbar/navbar";
import Hero from "../../section/hero/hero";
import About from "../../section/about/about";
import Promo from "../../section/promo/promo.jsx";
import Gallerylogo from "../../section/gallerylogo/gallerylogo";
import Produk from "../../section/produk/produk.jsx";
import Benefit from "../../section/benefit/benefit.jsx";
import Lokasi from "../../section/lokasi/lokasi.jsx";
import Footer from "../../section/footer/footer.jsx";

const home = () => {
  return (
    <div className="home">
      <Navbar />
      <Hero />
      <About />
      <Promo />
      <Gallerylogo />
      <Produk />
      <Benefit />
      <Lokasi />
      <Footer />
    </div>
  );
};

export default home;
