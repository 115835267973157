import Cove1 from "./cove (1).webp";
import Cove2 from "./cove (2).webp";
import Cove3 from "./cove (3).webp";
import Cove4 from "./cove (4).webp";
import Cove5 from "./cove (5).webp";
import Cove6 from "./cove (6).webp";
import Cove7 from "./cove (7).webp";
import Cove8 from "./cove (8).webp";

const cove = [Cove1, Cove2, Cove3, Cove4, Cove5, Cove6, Cove7, Cove8];

export default cove;
