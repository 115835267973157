import Northridge1 from "./northridge (1).webp";
import Northridge2 from "./northridge (2).webp";
import Northridge3 from "./northridge (3).webp";
import Northridge4 from "./northridge (4).webp";
import Northridge5 from "./northridge (5).webp";

const northridge = [
  Northridge1,
  Northridge2,
  Northridge3,
  Northridge4,
  Northridge5,
];

export default northridge;
