import Tekno1 from "./tekno (1).webp";
import Tekno2 from "./tekno (2).webp";
import Tekno3 from "./tekno (3).webp";
import Tekno4 from "./tekno (4).webp";
import Tekno5 from "./tekno (5).webp";
import Tekno6 from "./tekno (6).webp";
import Tekno7 from "./tekno (7).webp";
import Tekno8 from "./tekno (8).webp";
import Tekno9 from "./tekno (9).webp";
import Tekno10 from "./tekno (10).webp";

const tekno = [
  Tekno1,
  Tekno2,
  Tekno3,
  Tekno4,
  Tekno5,
  Tekno6,
  Tekno7,
  Tekno8,
  Tekno9,
  Tekno10,
];

export default tekno;
