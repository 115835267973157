import Latinos1 from "./latinos (1).webp";
import Latinos2 from "./latinos (2).webp";
import Latinos3 from "./latinos (3).webp";
import Latinos4 from "./latinos (4).webp";
import Latinos5 from "./latinos (5).webp";
import Latinos6 from "./latinos (6).webp";
import Latinos7 from "./latinos (7).webp";
import Latinos8 from "./latinos (8).webp";
import Latinos9 from "./latinos (9).webp";
import Latinos10 from "./latinos (10).webp";
import Latinos11 from "./latinos (11).webp";
import Latinos12 from "./latinos (12).webp";
import Latinos13 from "./latinos (13).webp";
import Latinos14 from "./latinos (14).webp";
import Latinos15 from "./latinos (15).webp";
import Latinos16 from "./latinos (16).webp";

const latinos = [
  Latinos1,
  Latinos2,
  Latinos3,
  Latinos4,
  Latinos5,
  Latinos6,
  Latinos7,
  Latinos8,
  Latinos9,
  Latinos10,
  Latinos11,
  Latinos12,
  Latinos13,
  Latinos14,
  Latinos15,
  Latinos16,
];

export default latinos;
