import Northpoin1 from "./northpoin (1).webp";
import Northpoin2 from "./northpoin (2).webp";
import Northpoin3 from "./northpoin (3).webp";
import Northpoin4 from "./northpoin (4).webp";
import Northpoin5 from "./northpoin (5).webp";
import Northpoin6 from "./northpoin (6).webp";
import Northpoin7 from "./northpoin (7).webp";
import Northpoin8 from "./northpoin (8).webp";

const northpoin = [
  Northpoin1,
  Northpoin2,
  Northpoin3,
  Northpoin4,
  Northpoin5,
  Northpoin6,
  Northpoin7,
  Northpoin8,
];

export default northpoin;
