import Delrey1 from "./delrey (1).webp";
import Delrey2 from "./delrey (2).webp";
import Delrey3 from "./delrey (3).webp";
import Delrey4 from "./delrey (4).webp";
import Delrey5 from "./delrey (5).webp";
import Delrey6 from "./delrey (6).webp";
import Delrey7 from "./delrey (7).webp";
import Delrey8 from "./delrey (8).webp";
import Delrey9 from "./delrey (9).webp";
import Delrey10 from "./delrey (10).webp";
import Delrey11 from "./delrey (11).webp";
import Delrey12 from "./delrey (12).webp";
import Delrey13 from "./delrey (13).webp";
import Delrey14 from "./delrey (14).webp";
import Delrey15 from "./delrey (15).webp";
import Delrey16 from "./delrey (16).webp";
import Delrey17 from "./delrey (17).webp";
import Delrey18 from "./delrey (18).webp";
import Delrey19 from "./delrey (19).webp";
import Delrey20 from "./delrey (20).webp";
import Delrey21 from "./delrey (21).webp";
import Delrey22 from "./delrey (22).webp";

const delrey = [
  Delrey1,
  Delrey2,
  Delrey3,
  Delrey4,
  Delrey5,
  Delrey6,
  Delrey7,
  Delrey8,
  Delrey9,
  Delrey10,
  Delrey11,
  Delrey12,
  Delrey13,
  Delrey14,
  Delrey15,
  Delrey16,
  Delrey17,
  Delrey18,
  Delrey19,
  Delrey20,
  Delrey21,
  Delrey22,
];

export default delrey;
