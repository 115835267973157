import React from "react";
import "./benefit.scss";
import {
  FaBuilding,
  FaCar,
  FaPeopleCarry,
  FaRoad,
  FaUser,
} from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";

const benefit = () => {
  return (
    <div id="benefit" className="benefit">
      <div className="dividera"></div>
      <div className="container-benefit">
        <div className="header-benefit">
          <div className="judul-benefit">Everything You Need In One Place</div>
        </div>
        <div className="list-benefit">
          <div className="list">
            <ul>
              <li>
                <FaUser size="70px" />
                &nbsp;&nbsp;
                <span>More Than 200.000+ Residents</span>
              </li>
              <li>
                <FaRoad size="70px" />
                &nbsp;&nbsp;
                <span>Easy Access</span>
              </li>
              <li>
                <FaPeopleCarry size="70px" />
                &nbsp;&nbsp;
                <span>More Than 500.000 Daily Activity</span>
              </li>
            </ul>
          </div>
          <div className="list">
            <ul>
              <li>
                <FaCar size="70px" />
                &nbsp;&nbsp;
                <span>Private Parking</span>
              </li>
              <li>
                <FaBuilding size="70px" />
                &nbsp;&nbsp;
                <span>Near 8 Luxury Residentials</span>
              </li>
              <li>
                <FaMapLocationDot size="70px" />
                &nbsp;&nbsp;
                <span>Strategic Location</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="dividerb" />
    </div>
  );
};

export default benefit;
