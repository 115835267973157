import React from "react";
import "./gallerylogo.scss";
import logo from "../../asset/logo/logo1";
import logo2 from "../../asset/logo/logo2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const gallerylogo = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        lazyload: true, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 3, // Show only 1 slide on mobile (optional)
          swipeToSlide: true,
          lazyload: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="container-gallery">
      <div className="judul-gallery">A Community of Thriving Tenants</div>
      <div className="gallerylogo">
        <Slider {...settings}>
          {logo.map((image, index) => (
            <div>
              <img
                className="kartugambar01"
                key={index}
                src={image}
                alt="logo"
              />
            </div>
          ))}
        </Slider>
        <Slider {...settings}>
          {logo2.map((image, index) => (
            <div>
              <img
                className="kartugambar01"
                key={index}
                src={image}
                alt="logo"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default gallerylogo;
