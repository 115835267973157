import React from "react";
import "./lokasi.scss";
import mapsimage from "./dekstop-maps.jpeg";
import mobilemaps from "./mobile-maps.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  return (
    <div className="lokasi">
      <div id="lokasi" className="judul">
        Lokasi
      </div>
      <div className="maps-lokasi">
        <img
          id="lokasi"
          className="img-lokasi-dekstop"
          src={mapsimage}
          alt="maps"
        />
        <img
          id="lokasi"
          className="img-lokasi-mobile"
          src={mobilemaps}
          alt="maps"
        />
      </div>
      <div className="benefit-container1">
        <div className="benefit-point2">
          <div className="benefit-title3">
            <FontAwesomeIcon icon={faGraduationCap} /> &nbsp;Education
          </div>
          <div className="benefit4">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;2 Menit ke IPK School
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke BPK Penabur
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke Al-Azhar BSD School
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke Atmajaya University
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;10 Menit ke Pelita Harapan University
            </div>
          </div>
        </div>
        <div className="benefit-point2">
          <div className="benefit-title3">
            <FontAwesomeIcon icon={faHouse} /> &nbsp;Residentials
          </div>
          <div className="benefit4">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;1 Menit ke Enchante Residence
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;2 Menit ke Zora Residence
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;3 Menit ke Nava Park
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;3 Menit ke Greenwich Park
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;3 Menit ke Jadeite Residence
            </div>
          </div>
        </div>
        <div className="benefit-point2">
          <div className="benefit-title3">
            <FontAwesomeIcon icon={faBuilding} /> &nbsp;Office Complex
          </div>
          <div className="benefit4">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;4 Menit ke Unilever Office
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;4 Menit ke Kanggo
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;10 Menit ke Evosys
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;10 Menit ke Graha Telkom
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;10 Menit ke OCBC
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
