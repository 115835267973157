import Cascade1 from "./cascade (1).webp";
import Cascade2 from "./cascade (2).webp";
import Cascade3 from "./cascade (3).webp";
import Cascade4 from "./cascade (4).webp";
import Cascade5 from "./cascade (5).webp";
import Cascade6 from "./cascade (6).webp";
import Cascade7 from "./cascade (7).webp";
import Cascade8 from "./cascade (8).webp";
import Cascade9 from "./cascade (9).webp";

const cascade = [
  Cascade1,
  Cascade2,
  Cascade3,
  Cascade4,
  Cascade5,
  Cascade6,
  Cascade7,
  Cascade8,
  Cascade9,
];

export default cascade;
