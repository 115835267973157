import React from "react";
import "./hero.scss";

const hero = () => {
  return (
    <div className="hero">
      <div className="container-hero"></div>
    </div>
  );
};

export default hero;
