import Vanya1 from "./vanya (1).webp";
import Vanya2 from "./vanya (2).webp";
import Vanya3 from "./vanya (3).webp";
import Vanya4 from "./vanya (4).webp";
import Vanya5 from "./vanya (5).webp";
import Vanya6 from "./vanya (6).webp";

const vanya = [Vanya1, Vanya2, Vanya3, Vanya4, Vanya5, Vanya6];

export default vanya;
