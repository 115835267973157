import Highlight1 from "./highlight (1).webp";
import Highlight2 from "./highlight (2).webp";
import Highlight3 from "./highlight (3).webp";
import Highlight4 from "./highlight (4).webp";
import Highlight5 from "./highlight (5).webp";
import Highlight6 from "./highlight (6).webp";

const highlight = [
  Highlight1,
  Highlight2,
  Highlight3,
  Highlight4,
  Highlight5,
  Highlight6,
];

export default highlight;
