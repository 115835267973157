import React from "react";
import "./footer.scss";
import logo from "../../asset/logo/bsd-logoc.webp";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-bsd" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h2>Marketing Gallery</h2>
            <h1>BSD City - Sinarmas Land</h1>
            <p>Lengkong Kulon, Pagedangan, Tangerang Regency, Banten 15331</p>
          </div>
          <div className="contact">Contact us : +6282112623779 </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">BSD CITY - SINARMAS LAND</div>
    </div>
  );
};

export default footer;
