import Theloop1 from "./theloop (1).webp";
import Theloop2 from "./theloop (2).webp";
import Theloop3 from "./theloop (3).webp";
import Theloop4 from "./theloop (4).webp";
import Theloop5 from "./theloop (5).webp";
import Theloop6 from "./theloop (6).webp";
import Theloop7 from "./theloop (7).webp";
import Theloop8 from "./theloop (8).webp";
import Theloop9 from "./theloop (9).webp";
import Theloop10 from "./theloop (10).webp";

const theloop = [
  Theloop1,
  Theloop2,
  Theloop3,
  Theloop4,
  Theloop5,
  Theloop6,
  Theloop7,
  Theloop8,
  Theloop9,
  Theloop10,
];

export default theloop;
