import React from "react";
import "./about.scss";
import { PiHouseLine } from "react-icons/pi";
import { BsCreditCard } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const about = () => {
  const waabout = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282112623779&text=Halo%20Keven,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20https://sinarmasland-bsdcity.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const wabrosur = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282112623779&text=Halo%20Keven,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20(brousur)%20https://sinarmasland-bsdcity.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="about" className="about">
      <div className="container-about">
        <div className="header-about">
          <div className="judul-about">Bumi Serpong Damai - BSD City </div>
          <div className="subjudul-about">BSD City, Banten</div>
          <div className="sec-about">
            <div className="home-about">
              <div className="icon-home-about">
                <PiHouseLine />
              </div>
              <div className="ket-home">
                <div className="judul-home-about">Tipe Propety</div>
                <div className="subjudul-home-about">Ruko Ready Dan Indent</div>
              </div>
              <div className="button-home-about">
                <button className="brosur" onClick={wabrosur}>
                  Brosur
                </button>
              </div>
            </div>
            <div className="pembayaran-about">
              <div className="icon-pembayaran-about">
                <BsCreditCard />
              </div>
              <div className="ket-pembayaran">
                <div className="judul-pembayaran-about">Jenis Pembayaran</div>
                <div className="subjudul-pembayaran-about">
                  Cash dan KPR 100% Approved
                </div>
              </div>
              <div className="button-pembayaran-about">
                <button className="carda" onClick={waabout}>
                  <span className="waicon">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </span>
                  &nbsp; Whatsapp
                </button>
              </div>
            </div>
          </div>
          <div className="about-us">
            <div className="judul-about-us">
              <b>Big City</b> Big Opotunity
            </div>
            <div className="subjudul-about-us">
              BSD City merupakan kota mandiri yang dikembangkan oleh Sinar Mas
              Land dan menjadi pusat hunian, edukasi, hiburan, dan inovasi
              digital di mana semua fasilitas disediakan, mulai dari kawasan
              industri, perkantoran, perdagangan, pendidikan, wisata, hingga
              perumahan. Sinar Mas Group mempopulerkan nama "BSD City," serta
              menambahkan slogan "Big City, Big Opportunity".
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
